import type { NextPage } from 'next';
import { MainDesktop } from 'views';
import { SEO } from 'components';
import { API } from 'API';
import {
  ArticleItem,
  Podcast,
  ScheduleProgram,
  ShowPlaylist,
  TeamMember,
  ShowSound,
  BoxInstanceId,
} from 'models';
import React from 'react';
import { replaceUndefinedWithNull } from 'utils/replaceUndefinedWithNull';

export interface IMain {
  soonOnAirContent: ArticleItem[];
  highlightedNewsContent: ArticleItem[];
  allNews: ArticleItem[];
  allNewsContent: ArticleItem[];
  teamContent: TeamMember[];
  actionsContent: ArticleItem[];
  lzrContent: ArticleItem[];
  latestBoxContent: ArticleItem;
  patronagesContent: ArticleItem[];

  podcastsContent: Podcast[];
  signalsOfTheDayContent: ArticleItem[];
  highNoonContent: ArticleItem[];
  moreOfTheWorldContent: ArticleItem[];
  musicContent: ArticleItem[];
  musicByNightContent: ArticleItem[];
  musicalJedynkaContent: ArticleItem[];
  goodOldDaysContent: ArticleItem[];
  scientificVertigoContent: ArticleItem[];
  eurekaContent: ArticleItem[];
  discoverersEveningContent: ArticleItem[];
  concertsContent: ArticleItem[];
  seriesContent: ArticleItem[];
  exclusivesContent: ArticleItem[];
  womansPurseContent: ArticleItem[];
  fourSeasonsContent: ArticleItem[];
  faceToFaceContent: ArticleItem[];
  culturedJedynkaContent: ArticleItem[];
  myBooksContent: ArticleItem[];
  mustReadContent: ArticleItem[];
  veryCinematicShowContent: ArticleItem[];
  scheduleContent: ScheduleProgram[];
  playlistContent: ShowPlaylist[];
  streamContent: ArticleItem;
  stream2Content: ArticleItem;
  stream3Content: ArticleItem;
  stream4Content: ArticleItem;
  bannerTopContent: ArticleItem;
  banner0Content: ArticleItem;
  banner1Content: ArticleItem;
  banner2Content: ArticleItem;
  bannerSpecialContent: ArticleItem;
  bannerPromo1Content: ArticleItem;
  bannerPromo2Content: ArticleItem;
  newsAudioBoxContent: ShowSound[];
  jedynkaBooksContent: ArticleItem[];
  wasReadContent: BoxInstanceId[];
  currentReadContent: BoxInstanceId[];
  matysiakowieContent: ShowSound[];
  jezioranyContent: ShowSound[];
  recordOfTheWeekContent: ArticleItem[];
  recommendedRecordsContent: ArticleItem[];
  newsBoxContent: ArticleItem[];
  inOpenCardsContent: ArticleItem[];
  whereIsMrCogitoContent: ArticleItem[];
  newsInfoContent: ArticleItem[];
  newsMosaicContent: ArticleItem[];
  worldOfEastContent: ArticleItem[];
  polishRulersAndKingsContent: ArticleItem[];
  liveHistoryContent: ArticleItem[];
  jedynkaExpressContent: ArticleItem[];
  allCultureContent: ArticleItem[];
  allHistoryContent: ArticleItem[];
  allSocietyContent: ArticleItem[];
  allScienceContent: ArticleItem[];
  allLiteratureContent: ArticleItem[];
}

const Home: NextPage<IMain> = (props) => {
  return (
    <>
      <SEO
        title="Jedynka - polskieradio.pl"
        description="Strona Główna Jedynki polskiego radia"
        image="https://static.prsa.pl/images/aeb399b3-8157-4aa3-8447-6b8cb96c5d13.jpg"
      />
      <MainDesktop {...props} />
    </>
  );
};

export default Home;

const sources = [
  {
    name: 'news',
    id: 1552,
  },
  {
    name: 'allNews',
    id: 3,
    length: 9,
  },
  {
    name: 'signalsOfTheDay',
    id: 129,
  },
  {
    name: 'moreOfTheWorld',
    id: 1696,
  },
  {
    name: 'highNoon',
    id: 473,
  },
  {
    name: 'inOpenCards',
    id: 8805,
  },
  {
    name: 'whereIsMrCogito',
    id: 7630,
  },
  {
    name: 'newsInfo',
    id: 1552,
    instanceId: 80856,
  },
  {
    name: 'musicalJedynka',
    id: 160,
    length: 3,
  },
  {
    name: 'musicByNight',
    id: 3730,
    length: 3,
  },
  {
    name: 'actions',
    id: 1579,
    length: 9,
  },
  {
    name: 'lzr2023',
    id: 10302,
    length: 9,
  },

  {
    name: 'music',
    id: 1814,
  },
  {
    name: 'goodOldDays',
    id: 3727,
  },
  {
    name: 'scientificVertigo',
    id: 179,
    length: 6,
  },
  {
    name: 'eureka',
    id: 5098,
    length: 6,
  },
  {
    name: 'discoverersEvening',
    id: 161,
    length: 6,
  },
  {
    name: 'series',
    id: 5609,
    length: 6,
  },
  {
    name: 'exclusives',
    id: 3040,
    length: 6,
  },
  {
    name: 'womansPurse',
    id: 5071,
    length: 6,
  },
  {
    name: 'fourSeasons',
    id: 163,
    length: 6,
  },
  {
    name: 'faceToFace',
    id: 5112,
    length: 6,
  },
  {
    name: 'culturedJedynka',
    id: 178,
    length: 6,
  },
  {
    name: 'mustRead',
    id: 1530,
    length: 6,
  },
  {
    name: 'veryCinematicShow',
    id: 4457,
    length: 6,
  },
  {
    name: 'jedynkaBooks',
    id: 1530,
    length: 6,
  },
  {
    name: 'myBooks',
    id: 173,
    length: 6,
  },
  {
    name: 'recordOfTheWeek',
    id: 4452,
    length: 1,
  },
  {
    name: 'recommendedRecords',
    id: 4452,
    length: 10,
  },
  {
    name: 'concerts',
    id: 3729,
  },
  {
    name: 'science',
    id: 1550,
  },
  {
    name: 'worldOfEast',
    id: 5103,
  },
  {
    name: 'polishRulersAndKings',
    id: 4814,
  },
  {
    name: 'liveHistory',
    id: 5102,
  },
  {
    name: 'jedynkaExpress',
    id: 5346,
  },
  {
    name: 'allCulture',
    id: 1551,
    length: 6,
  },
  {
    name: 'allHistory',
    id: 4565,
    length: 6,
  },
  {
    name: 'allSociety',
    id: 4411,
    length: 6,
  },
  {
    name: 'allScience',
    id: 1550,
    length: 6,
  },
  {
    name: 'allLiterature',
    id: 9639,
    length: 6,
  },
];

interface Content {
  name: string;
  data: ArticleItem[];
}

export async function getStaticProps() {
  const content: Content[] = [];

  await Promise.all(
    sources.map(async (source) => {
      const selectedArticles =
        source.instanceId &&
        (await API.getArticlesByBoxInstanceId(source.instanceId, 5, 0));
      const res = await API.getArticles(source.id, source.length || 5, 0);
      if (selectedArticles && res) {
        const selected = selectedArticles.data.data;
        const newData = [...selected, ...res?.data.data];

        // remove duplicate
        const seen = new Set();
        const filteredArr = newData.filter((el) => {
          const duplicate = seen.has(el.id);
          seen.add(el.id);
          return !duplicate;
        });

        content.push({ name: source.name, data: filteredArr || [] });
      } else {
        content.push({ name: source.name, data: res?.data?.data || [] });
      }
    })
  );

  const podcastsResponse = await API.getPodcasts();
  const teamResponse = await API.getTeam();
  const scheduleResponse = await API.getSchedule(new Date().toDateString());
  const playlistResponse = await API.getPlaylist(new Date().toDateString());
  const newsAudioBoxResponse = await API.getRadioShowSounds(10548, 0, 1);
  const matysiakowieResponse = await API.getSpecialBroadcastSounds(4721, 0, 1);
  const jezioranyResponse = await API.getSpecialBroadcastSounds(4733, 0, 1);
  const streamResponse = await API.getSingleArticle(2867377, true);
  const stream2Response = await API.getSingleArticle(2906089, true);
  const stream3Response = await API.getSingleArticle(3029813, true);
  const stream4Response = await API.getSingleArticle(3084525, true);
  const bannerTopResponse = await API.getSingleArticle(3112018, true);
  const banner0Response = await API.getSingleArticle(2910942, true);
  const banner1Response = await API.getSingleArticle(2870802, true, true);
  const banner2Response = await API.getSingleArticle(2870806, true);
  const latestBoxResponse = await API.getSingleArticle(2994065, true);
  // const bannerSpecialResponse = await API.getSingleArticle(2870838, true);
  const bannerPromo1Response = await API.getSingleArticle(2871246, true);
  const bannerPromo2Response = await API.getSingleArticle(2871255, true);
  const wasReadResponse = await API.getArticlesByBoxInstanceId(77591, 8, 0);
  const currentReadResponse = await API.getArticles(9696, 8, 0);
  const newsBoxResponse = await API.getArticlesByBoxInstanceId(77569, 5, 0);
  const soonOnAirResponse = await API.getArticlesByBoxInstanceId(77565, 6, 0);
  const newsMosaicResponse = await API.getArticlesByBoxInstanceId(77572, 5, 0);
  const highlightedNewsResponse = await API.getArticlesByBoxInstanceId(
    42011,
    1,
    9
  );
  const patronagesResponse = await API.getArticles(10269, 3, 0);
  const lzrResponse = await API.getTagsResults(191506, 10, 0);
  const allNewsResponse = await API.getArticles(3, 5, 0);
  console.log('env: ', process.env.ENVIRONMENT);
  console.log({ streamResponse });
  const props = {
    ...content.reduce(
      (r, c) => Object.assign(r, { [`${c.name}Content`]: c.data }),
      {}
    ),
    podcastsContent: podcastsResponse?.data?.items || null,
    teamContent: teamResponse?.data?.data || null,
    scheduleContent: scheduleResponse?.data?.data || null,
    playlistContent: playlistResponse?.data?.data || null,
    streamContent:
      streamResponse?.data?.data?.customFields
        ?.find((customField) => customField.name === 'isHidden')
        ?.value?.toLowerCase() === 'true' &&
      process.env.ENVIRONMENT !== 'staging'
        ? null
        : streamResponse?.data?.data,
    stream2Content:
      stream2Response?.data?.data?.customFields
        ?.find((customField) => customField.name === 'isHidden')
        ?.value?.toLowerCase() === 'true' &&
      process.env.ENVIRONMENT !== 'staging'
        ? null
        : stream2Response?.data?.data,
    stream3Content:
      stream3Response?.data?.data?.customFields
        ?.find((customField) => customField.name === 'isHidden')
        ?.value?.toLowerCase() === 'true' &&
      process.env.ENVIRONMENT !== 'staging'
        ? null
        : stream3Response?.data?.data,
    stream4Content:
      stream4Response?.data?.data?.customFields
        ?.find((customField) => customField.name === 'isHidden')
        ?.value?.toLowerCase() === 'true' &&
      process.env.ENVIRONMENT !== 'staging'
        ? null
        : stream4Response?.data?.data,
    bannerTopContent:
      bannerTopResponse?.data?.data?.customFields
        ?.find((customField) => customField.name === 'isHidden')
        ?.value?.toLowerCase() === 'true' &&
      process.env.ENVIRONMENT !== 'staging'
        ? null
        : bannerTopResponse?.data?.data,
    banner0Content: banner0Response?.data?.data || null,
    banner1Content: banner1Response?.data?.data || null,
    banner2Content: banner2Response?.data?.data || null,
    // bannerSpecialContent: bannerSpecialResponse?.data?.data || null,
    bannerPromo1Content: bannerPromo1Response?.data?.data || null,
    bannerPromo2Content: bannerPromo2Response?.data?.data || null,
    newsAudioBoxContent: newsAudioBoxResponse?.data?.data || null,
    wasReadContent: wasReadResponse?.data?.data || null,
    currentReadContent: currentReadResponse?.data?.data || null,
    matysiakowieContent: matysiakowieResponse?.data?.data || null,
    jezioranyContent: jezioranyResponse?.data?.data || null,
    newsBoxContent: newsBoxResponse?.data?.data || null,
    soonOnAirContent: soonOnAirResponse?.data?.data || null,
    newsMosaicContent: newsMosaicResponse?.data?.data || null,
    highlightedNewsContent: highlightedNewsResponse?.data?.data || null,
    allNewsContent: allNewsResponse?.data?.data || null,
    lzrContent: lzrResponse?.data?.data || null,
    latestBoxContent: latestBoxResponse?.data?.data || null,
    patronagesContent: patronagesResponse?.data?.data || null,
  };
  return {
    props: replaceUndefinedWithNull(props),
    revalidate: 10,
  };
}
